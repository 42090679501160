






























































































import { Vue, Component } from 'vue-property-decorator'
import { UserPosition } from '../../types/user'
import gql from 'graphql-tag'
import { sendSms } from '@/utils/sms'

@Component
export default class extends Vue {
  private emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i
  private username = ''
  private firstname = ''
  private firstnameEn = ''
  private lastname = ''
  private lastnameEn = ''
  private nickname = ''
  private nicknameEn = ''
  private email = ''
  private phone = ''
  private positions = [UserPosition.staff, UserPosition.student]
  private position = UserPosition.staff
  private get formValidation() {
    return (
      /^\d{5}$/.test(this.username) &&
      this.firstname.length > 0 &&
      this.firstnameEn.length > 0 &&
      this.lastname.length > 0 &&
      this.lastnameEn.length > 0 &&
      this.nickname.length > 0 &&
      this.nicknameEn.length > 0 &&
      this.emailReg.test(this.email) &&
      /^\d{10}$/.test(this.phone)
    )
  }

  private loading = false

  private async submit() {
    this.loading = true
    const { data } = await this.$apollo.mutate<{
      createUser: { password: string }
    }>({
      mutation: gql`
        mutation CreateNewUser($data: CreateUserInput!) {
          createUser(data: $data) {
            password
          }
        }
      `,
      variables: {
        data: {
          username: this.username,
          firstName: this.firstname,
          firstNameEn: this.firstnameEn,
          lastName: this.lastname,
          lastNameEn: this.lastnameEn,
          nickname: this.nickname,
          nicknameEn: this.nicknameEn,
          email: this.email,
          position: this.position,
          phone: [this.phone],
        },
      },
    })
    if (data) {
      await sendSms(
        `Welcome to Monkeymonkey :)\nUsername: ${this.username}\nPassword: ${data.createUser.password}`,
        this.phone,
        'false'
      )
    }

    this.loading = false
  }

  private resetForm() {
    this.username = ''
    this.firstname = ''
    this.firstnameEn = ''
    this.lastname = ''
    this.lastnameEn = ''
    this.nickname = ''
    this.nicknameEn = ''
    this.email = ''
    this.position = UserPosition.staff
    this.phone = ''
  }
}
